import "../Scss/styles.scss";
import {Offcanvas} from "bootstrap";
import SimpleLightbox from "simplelightbox";

const lightbox = new SimpleLightbox('.gallery a', {});
const section = document.querySelectorAll("section");
let sections = {};
let i = 0;

Array.prototype.forEach.call(section, function(e) {
    sections[e.id] = e.offsetTop;
});

window.onscroll = function() {
    if (typeof sections !== 'undefined' && sections !== null) {
        const scrollPosition = document.documentElement.scrollTop;

        for (i in sections) {
            if (sections[i] <= (scrollPosition + 93)) {
                const activeNavItem = document.querySelector('#scrollspy .active');
                if (typeof activeNavItem !== 'undefined' && activeNavItem !== null) {
                    activeNavItem.setAttribute('class', 'nav-link');
                }
                const targetNavItem = document.querySelector('#scrollspy a[href*=' + i + ']');
                if (typeof targetNavItem !== 'undefined' && targetNavItem !== null) {
                    targetNavItem.setAttribute('class', 'nav-link active');
                }
            }
        }
    }
}

const offcanvasElementsList = document.querySelectorAll('.offcanvas');
const offcanvasList = [...offcanvasElementsList].map(offcanvasElement => new Offcanvas(offcanvasElement));
